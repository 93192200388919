<template>
    <div v-if="detail.commodity" class="fitbox-side-box">
        <div class="fitbox-side-head">
            <h3 class="fitbox-side-title">关联产品</h3>
            <!-- <div class="fitbox-side-tool">
                共&nbsp;1&nbsp;款
            </div> -->
        </div>
        <div class="fitbox-side-body">
            <router-link :to="`/product/detail?commodityid=${detail.commodity.commodityid}`">
                <img class="detail-side-img" :src="detail.commodity.img" width="245" height="245" alt="detail.commodity.title">
                <span class="detail-side-name text-ellipsiss" :title="detail.commodity.title">{{ detail.commodity.title }}</span>
                <b class="detail-side-price"><i>￥</i>{{ detail.commodity.money1 === '0.00' ? detail.commodity.money2 : `${detail.commodity.money1}-${detail.commodity.money2}` }}</b>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SideCommodity',
    props: {
        detail: {
            type: Object,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
.detail-side-name {
    display: block;
    line-height: 20px;
    margin-bottom: 10px;
}
.detail-side-price {
    font-weight: bold;
    font-size: 18px;
    color: $red;
    > i {
        font-size: 12px;
    }
}
</style>
