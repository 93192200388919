<template>
    <div class="fitbox-side-box">
        <div class="fitbox-side-head">
            <h3 class="fitbox-side-title">资讯图册</h3>
            <div class="fitbox-side-tool">
                共&nbsp;{{ detail.imgarr.length }}&nbsp;张图片
            </div>
        </div>
        <div class="fitbox-side-body">
            <el-image
                v-if="detail.imgarr[0]"
                ref="sideImageView"
                style="width: 245px; height: 245px"
                :src="detail.imgthumbnail"
                :preview-src-list="detail.imgarr.map(item => item.url)"
            >
                <img slot="placeholder" class="img-placeholder" :src="require('@a/img/placeholder.jpg')" alt="">
            </el-image>
            <!-- <el-image :img-list="detail.imgarr.map(item => item.url)" /> -->
            <div class="between-item">
                <span>定价</span>
                <!-- <b>{{ detail.moneytype === '1' ? '未定' : (detail.dollar!=='0.00' ? `${detail.dollar}美元` : detail.yen !== '0.00' ? `${detail.yen}日元` : '') + `（${detail.rmb}元）` }}</b> -->
                <b>{{ $utils.getMoneyString(detail.moneytype, detail.dollar, detail.yen, detail.rmb) }}</b>
            </div>
            <div class="between-item">
                <span>出荷</span>
                <b>{{ $utils.getPublishString(detail.publishtype, detail.publishyear, detail.publishtime) }}</b>
            </div>
            <div class="between-item">
                <span>制作</span>
                <b>kamisoul</b>
            </div>
            <div class="between-item">
                <span>系列</span>
                <b>{{ detail.seriestitle }}</b>
            </div>
        </div>
    </div>
</template>
<script>
// import elImage from './elImage'
export default {
    name: 'SideImgList',
    // components: {
    //     elImage
    // },
    props: {
        detail: {
            type: Object,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
.fitbox-side-body {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 285px;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: $grayest;
    }
}
.el-image {
    margin-bottom: 50px;
}
.img-placeholder {
    width: 100%;
    height: 100%;
}
</style>
